.geofence-list {
  height: calc(100vh - 415px);
  max-height: calc(100vh - 415px);
  overflow-y: scroll;
}

.geofence-list .MuiTableCell-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

input[type=color] {
  height: 26px !important;
}